import { industryValues } from '../../../src/constants/industry.constants';

export const EXEPTION_HEADERS = [
  '/',
  '/event/sale',
  '/mypage/clip-book',
  '/mypage/cart',
  '/search',
  '/new',
  '/order/checkout',
  '/brand/:id',
  '/adult',
  '/community',
  '/users/login',
  '/event/sale',
  '/event/first-purchase',
  '/imagesource',
  '/classproduct',
  '/event/thousand-shop',
  '/event/2023-black-friday',
  '/order/enterprise-checkout',
  '/order/success',
  '/order/sketch-up',
  '/event/goodbye-2023',
  '/event/new-year-2024',
  '/acon-only',
  '/users/login/complete',
];
export const EXEPTION_HEADERS_STARTHWITH = [
  '/category',
  '/brand',
  '/community',
  '/mypage/gift',
  '/tabs',
  '/product/',
  '/product-preview/[...docId]',
  // (23.12) 추후 생성되는 industry들은 반응형이 고려된 페이지일 가능성이 높으므로 따로 예외를 처리할 일 없도록 처리
  ...industryValues.map((industry) => `/${industry}`),
];
export const ABLUR_FAB_VISIBLE_PAGES = ['/', '/acon-only', '/new', '/event/sale', '/best', '/adult', '/event', '/photoreviews', '/community', '/category/[id]', '/search'];
