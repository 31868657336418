import React from 'react';
import NextHead from 'next/head';
import { useTranslation } from 'next-i18next';

interface propType {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImageOriginal?: boolean;
  ogImageUrl?: string;
  ogTwitterTitle?: string;
  ogTwitterCardSize?: string;
}

// 공용적으로 사용되는 head 태그 들에 대하여 정의되어 있는 컴포넌트입니다.
export const Head: React.FC<propType> = ({
  title = '',
  description = '',
  ogImageOriginal = false,
  ogImageUrl = '',
  ogUrl = '',
  ogTitle = '',
  ogTwitterTitle = '',
  ogDescription = '',
  ogTwitterCardSize = '',
}) => {
  // 번역 도구
  const { i18n } = useTranslation();

  const headTitle = title
    ? title
    : (function () {
        if (i18n.language === 'ko') {
          return 'ACON - 창작을 위한 디지털 에셋 스토어';
        }
        if (i18n.language === 'zh') {
          return '3D模型背景平台 - ACON3D';
        }
        if (i18n.language === 'ja') {
          return 'ACON - クリエイターの創作のための素材ストア';
        }
        return 'ACON - Digital Asset Store for All Creators';
      })();
  const keywords = (function () {
    if (i18n.language === 'ko') {
      return '클립스튜디오, 프로크리에이트, 스케치업, 브러쉬, 오토액션, 3D배경, 웹툰 배경, 데포르메, 3D인체, 웹툰 소스, 3D 모델';
    }
    if (i18n.language === 'zh') {
      return 'webtoon背景，3d設計，3d模型，動畫，設計，遊戲，建築, 建模, webtoon background, 3d design, 3d model, 3d, vr, ar, animation, design, sketchup, 3dmax, game, architechture webtoon, webcomics, modeling';
    }
    if (i18n.language === 'ja') {
      return 'クリスタ、プロクリエイト、SketchUp、ブラシ、オートアクション、3D背景、ウェブトゥーン背景、デフォルメ、3D体型、ウェブトゥーンソース、3Dモデル';
    }

    return 'Clip Studio, Procreate, SketchUp, Brush, Auto Action, 3D Background, Webtoon Background, Deforme, 3D Body, Webtoon Source, 3D Model';
  })();
  const headDescription = description
    ? description
    : (function () {
        if (i18n.language === 'ko') {
          return '디지털 에셋 스토어. 드로잉을 위한 브러쉬부터 3D 인체모델, 배경, 포즈집까지 창작에 필요한 모든 에셋을 자유롭게 사용하세요.';
        }
        if (i18n.language === 'zh') {
          return '韩国最大的3D模型漫画背景以及素材平台！ 在ACON3D扩展你的创作世界吧！';
        }
        if (i18n.language === 'ja') {
          return '創作に役立つブラシから3D体型、背景、ポーズ集、小物など、様々な素材を自由に活用できます。';
        }

        return 'Digital Asset Store. Use brushes, 3D backgrounds & animated deformes for all creative fields!';
      })();

  const headOgTitle = ogTitle ? ogTitle : headTitle;
  const headOgDescription = ogDescription
    ? ogDescription
    : i18n.language === 'ja'
    ? '創作に役立つブラシから3D体型、背景、ポーズ集、小物など、様々な素材を自由に活用できます。'
    : headDescription;
  const headOgUrl = ogUrl ? ogUrl : 'www.acon3d.com';
  const headOgImageUrl = ogImageOriginal
    ? ogImageUrl
    : ogImageUrl
    ? `${process.env.NEXT_PUBLIC_IMAGEOPTIMIZER}/?image=${ogImageUrl}&width=1200&quality=95`
    : 'https://www.acon3d.com/data/common/snsRepresentImage.png';
  const headOgTwitterCardSize = ogTwitterCardSize ? ogTwitterCardSize : 'summary';

  return (
    <>
      <NextHead>
        <title>{headTitle}</title>

        {/* 인코딩 및 import file */}
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content={'width=device-width, initial-scale=1, maximum-scale=1'} />
        <link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/favicon/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/favicon/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/favicon/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/favicon/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/favicon/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="author" content="ACON3D" />

        {/* 다국어 처리 */}
        <link className="default" rel="canonical" href="https://www.acon3d.com" />
        <link className="ko" rel="alternate" hrefLang="ko-KR" href="https://www.acon3d.com/ko" />
        <link className="en" rel="alternate" hrefLang="en-US" href="https://www.acon3d.com/en" />
        <link className="jp" rel="alternate" hrefLang="ja-JP" href="https://www.acon3d.com/ja" />
        <link className="cn" rel="alternate" hrefLang="zh-CN" href="https://www.acon3d.com/zh" />

        <meta name="og:site_name" property="og:site_name" content="ACON" />

        {/* open graph */}
        <meta name="title" property="title" content={headTitle} />
        <meta name="description" property="description" content={headDescription} />
        <meta name="keywords" property="keywords" content={keywords} />
        <meta name="og:title" property="og:title" content={headOgTitle} />
        <meta name="og:description" property="og:description" content={headOgDescription} />
        <meta name="og:url" property="og:url" content={headOgUrl} />
        <meta name="og:image" property="og:image" content={headOgImageUrl} />

        {/* twitter 용 */}
        <meta name="twitter:title" property="twitter:title" content={ogTwitterTitle ? ogTwitterTitle : headOgTitle} />
        <meta name="twitter:description" property="twitter:description" content={headOgDescription} />
        <meta name="twitter:url" property="twitter:url" content={headOgUrl} />
        <meta name="twitter:image" property="twitter:image" content={headOgImageUrl} />
        <meta name="twitter:card" property="twitter:card" content={headOgTwitterCardSize} />
      </NextHead>
    </>
  );
};
