import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { throttleWrapper } from '@util/eventHandling';
import { simplyStatusCode } from './Errors.constants';
import { IErrors } from './Errors.types';
import { ErrorsContainer, ErrorsControlButtons, ErrorsDescription, ErrorsImage } from './Errors.styles';

const Errors = ({ statusCode, locale, error }: IErrors): React.ReactElement => {
  const router = useRouter();

  const { t } = useTranslation(['error']);

  const errorCodeMerged = simplyStatusCode(statusCode || error?.extra?.status);

  const onClickBack = throttleWrapper(() => {
    router.back();
  });
  const onClickHome = throttleWrapper(() => {
    router.push('/');
  });

  useEffect(
    () => () => {
      if (errorCodeMerged === 404) {
        window.onpopstate = () => {
          location.reload();
        };
      }
    },
    [],
  );

  return (
    <ErrorsContainer>
      <ErrorsImage src={`https://storage.acon3d.com/public-images/error/${errorCodeMerged}.svg`} />
      {['en', 'ko', 'zh', 'ja'].includes(locale) || !locale ? (
        <>
          <ErrorsDescription>{t(`error:code.${errorCodeMerged}`)}</ErrorsDescription>
          <ErrorsControlButtons>
            <button className="home" onClick={onClickHome}>
              {t('error:home')}
            </button>
            <button className="back" onClick={onClickBack}>
              {t('error:back')}
            </button>
          </ErrorsControlButtons>
        </>
      ) : null}
    </ErrorsContainer>
  );
};

export default Errors;
