//
//  해당 파일은 전역적으로 사용되는 상수들에 대하여 정의하기 위해 생성한 파일입니다.
//

// 상품에 대한 내부 정의 이름
export const GOODS_INTERNAL_NAME = 'goods';

// 배너에 대한 내부 정의 이름
export const BANNER_INTERNAL_NAME = 'banner';

// 고정배너에 대한 내부 정의 이름
export const FIXED_BANNER_INTERNAL_NAME = 'fixedBanner';

export const BEST_INTERNAL_NAME = 'best';

export const HOT_INTERNAL_NAME = 'hot';

export enum LANGUAGE_CODE {
  KO = 'ko',
  EN = 'en',
  CN = 'zh',
  JP = 'ja',
}
export type LanguageCode = (typeof LANGUAGE_CODE)[keyof typeof LANGUAGE_CODE];

export const IS_PROD = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_STAGING !== 'true';

// 기획전 카테고리 코드
export const CATEGORY_CODE_EXHIBITION = '276002';

// 각 페이지에  대한 내부 정의 코드
export enum PAGE_NAME {
  // 검색 결과 페이지에 대한 내부 정의 코드
  SEARCH = 'search',
  // 카테고리 페이지에 대한 내부 정의 코드
  CATEGORY = 'category',
  // 브랜드 페이지에 대한 내부 정의 코드
  BRAND = 'brand',
  // 신작 페이지에 대한 내부 정의 코드
  NEW = 'new',
  // 브랜드 페이지에 대한 내부 정의 코드
  SALE = 'sale',
  // 혜택관 페이지에 대한 내부 정의 코드 ( 추후 독점관으로 통폐합 예정 )
  COMET_DEAL = 'comet-deal',
  // 우주최저가 페이지에 대한 내부 정의 코드 ( 추후 독점관으로 통폐합 예정 )
  GAME = 'game',
  ADULT = 'adult',
  CLASS_PRODUCT = 'classproduct',
  IMAGE_SOURCE = 'imagesource',
  EVENT = 'event',
  ACON_ONLY = 'acon-only',
}

// 확장자 코드
export enum EXTENSION_CODE {
  SKP = 'skp',
  BLEND = 'blend',
  ETC = 'etc',
}

export enum APPLICATION_CODE {
  ABLER = 'Abler',
}

export const mainTopSlideCodePc = {
  ko: 1231756217,
  en: 2303568241,
  zh: 1205716091,
  ja: 1020654418,
};
export const mainTopSlideCodeTablet = {
  ko: 1122280607,
  en: 741777235,
  zh: 768434286,
  ja: 1296492267,
};
export const mainTopSlideCodeMobile = {
  ko: 4267842447,
  en: 1067194071,
  zh: 2545322572,
  ja: 4019626305,
};

// 메인 프로모션 타이머 표시 구좌 고유 번호들
export const mainProductTimerSno = 16;

// 알림 정보 정의 값
export enum NOTIFICATION_CODE {
  ACON_CASH = 'aconcash',
}

// 대분류 정보
export const MAIN_CATEGORY = {
  CARTOON: 'toonstyle',
  REALISTIC: 'realistic',
};

// 대분류 리스트

// 애니메이션 정의 코드
export const TRANSITION_CODE = {
  /** 표시 중 */
  ENTERING: 'entering',
  /** 표시 완료 */
  ENTERED: 'entered',
  /** 닫는 중 */
  EXITING: 'exiting',
  /** 닫기 완료 */
  EXITED: 'exited',
};

// 카테고리 코드 정보
export const CATEGORY_CODE = {
  /** 주제별 */
  SUBJECT: '269',
  /** 장르별 */
  GENRE: '251',
  /** 실사렌더 */
  REALISTIC: '312',
  /** 에이블러 */
  ABLER: '282003',
  /** 구 에이블러 카테고리 */
  OLD_ABLER: '282002001',
  // 블랙 프라이데이
  BLACK_FRIDAY: '097117',
  // 진돌 X 히디
  JINDOL_HIDI: '097147',
};

// 카테고리 SNO 정보
export const CATEGORY_SNO = {
  // 신작 SNO 번호
  NEW: 30,
  // 이주의 에이블러 Pick SNO 번호
  ABLER: 36,
  // 게임 탭 구좌 1
  GAME1: 37,
  // 게임 탭 구좌 2
  GAME2: 38,
  // 게임 탭 구좌 3
  GAME3: 39,
  // 게임 탭 구좌 4
  GAME4: 40,
};

export const PAGE_TYPE = {
  TOON: 'toon',
  GAME: 'game',
};

export enum SIZE {
  /** 600 ~ */
  DESKTOP = 'md',
  /** 0 ~ 600 */
  MOBILE = 'xs',
}

export const headerNamespaces = ['unsupported', 'bandBanner', 'mode', 'game', 'menu'];

export const footerNamespaces = ['footer'];

export const defaultNamespaces = [...headerNamespaces, ...footerNamespaces, 'common', 'error', 'modal', 'message'];

export const azstorage = 'https://storage.acon3d.com';
export const getStorageImage = (basePath: string, path?: string) => (path && basePath ? azstorage + basePath + path : 'about:blank');

export const SERVICE_NAME = 'acon3d';
