import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EVENTS, TEVENTS_PERIOD } from './useOnGoingEvent.type';

dayjs.extend(utc);
dayjs.extend(timezone);

export const EVENTS_PERIOD: TEVENTS_PERIOD = {
  [EVENTS.LOGIN_BONUS_2407]: {
    since: dayjs('2024-07-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-07-31 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.LOGIN_BONUS_2408]: {
    since: dayjs('2024-08-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-08-31 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.CHARGE_DOUBLE_2407]: {
    since: dayjs('2024-07-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-07-03 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.CHARGE_DOUBLE_2408]: {
    since: dayjs('2024-08-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-08-03 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.CATEGORY_TOOLTIP]: {
    since: dayjs('2024-03-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-04-25 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.DAILY_BONUS_2024]: {
    since: dayjs('2024-06-17 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-06-23 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.ABLUR_LAUNCHING]: {
    since: dayjs('2024-07-15 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-08-12 13:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.ACON_PIXIV_240816]: {
    since: dayjs('2024-08-16 18:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-13 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.ACON_PIXIV_240817]: {
    since: dayjs('2024-08-17 16:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-13 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.ACON_PIXIV_240824]: {
    since: dayjs('2024-08-24 16:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-13 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.ACON_PIXIV_240825]: {
    since: dayjs('2024-08-25 16:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-13 23:59:59').tz('Asia/Seoul'),
  },
};

const generateInitialEventsState = () => {
  const result = [];
  const now = dayjs(); // 로컬 시간 세팅(현재시간)
  for (const EVENT_VALUE of Object.keys(EVENTS_PERIOD)) {
    const isBetween =
      now.isSame(EVENTS_PERIOD[EVENT_VALUE].since) ||
      now.isSame(EVENTS_PERIOD[EVENT_VALUE].until) ||
      (now.isAfter(EVENTS_PERIOD[EVENT_VALUE].since) && now.isBefore(EVENTS_PERIOD[EVENT_VALUE].until));
    isBetween && result.push(EVENT_VALUE);
  }
  return result;
};

export const INITIAL_ONGOING_EVENT_STATE = {
  onGoingEvents: generateInitialEventsState(),
};
