import React from 'react';

export default function NewBadgeIcon({ width, height }: { width: number; height: number }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill="#FF5C00" />
      <path d="M11.3667 3.97266V11.75H9.80908L6.65088 7.18457H6.59717V11.75H4.771V3.97266H6.3501L9.47607 8.53809H9.54053V3.97266H11.3667Z" fill="white" />
    </svg>
  );
}
