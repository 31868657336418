import Decimal from 'decimal.js';
import { captureException } from '@util/sentry';
import { LANGUAGE_CODE, LanguageCode } from '../constants';

/**
 * 에이콘에서 내부적으로 상품 가격, 포인트 등을 환전할 때 사용하는 환율이다.
 * - 고정환율을 사용하고 있다.
 * - 원화 기준 환율만 고려된다. (현재 고도몰 상품 가격의 기준이 원화이기 때문)
 * - 원화와 달러 사이의 환율만 고려된다. (해외 결제시 실제 결제가 달러 기준으로 이루어지기 때문)
 * @return {number} 1 to currency = {exchangeRate} from currency, 지원하지 않는 화폐쌍의 경우 undefined를 반환
 */
export function exchangeRate(from: LanguageCode, to: LanguageCode): number | undefined {
  if (from === to) {
    return 1;
  }

  if (from === LANGUAGE_CODE.KO && to === LANGUAGE_CODE.EN) {
    return 1000;
  }
  if (from === LANGUAGE_CODE.EN && to === LANGUAGE_CODE.KO) {
    return 0.001;
  }

  return undefined;
}

/**
 * 금액의 할인률을 가져오는 메소드입니다.
 * @param before 할인 전 금액
 * @param after 할인 후 금액
 */
export function discountAmount(before: number, after: number) {
  if (!after) {
    return 100;
  }

  return 100 - Math.ceil((after / before) * 100);
}

/**
 * 상품 가격은 USD 기준으로 결제가 되지만, 유저는 자국 통화로 결제를 하는 경우가 있음.
 * 이 경우에 유저가 최종적으로 결제하게 되는 금액을 계산.
 * @param {number} usdAmount - 상품의 실제 가격
 * @param {number} pgRate - 환율, 대상 단위 화폐가 원화로 얼마인지. (ex. 달러 => 1200원/달러)
 * @return 결제 예정 금액, 사용자 표시 가격
 */
export function estimatedPaymentAmount(usdAmount: number, pgRate: number): number {
  return _simpleExchange(usdAmount, pgRate);
}

/**
 * 원화 기준인 원래의 가격을 외국 화폐 기준으로 변환한다.
 * @param {number} koAmount - 상품의 실제 가격 (원화 기준)
 * @param {number} exchangeRate - 환율, 대상 단위 화폐가 원화로 얼마인지. (ex. 달러 => 1200원/달러)
 */
export function exchangeIntoForeignCurrency(koAmount: number, exchangeRate: number): number {
  return _simpleExchange(koAmount, exchangeRate);
}

function _simpleExchange(amount: number, rate: number): number {
  try {
    return Decimal.div(amount, rate).toDecimalPlaces(2, Decimal.ROUND_FLOOR).toNumber();
  } catch (e) {
    captureException(e, { extra: { amount, rate } });
    return 0;
  }
}

/**
 * 외국 화폐로 표시되는 가격을, 실질적인 계산을 위해서 원화로 다시 복원한다.
 * 고도몰 연동 이슈로 가격 관련 로직은 모두 원화로 처리된다.
 * 사용자에게 편의상 달러 등 외국 화폐로 표시해주는 것이며, 실질적인 주문 처리 등을 위해서는 원화로 원복을 해야 한다.
 * 이 때, 환율과 표시 자리수에 따라서 같은 금액이 계산되는 원화가 여럿이 있을 수 있다 (ex. 160 원/위안 일 때, 550원 = 3.43위안 = 549원)
 * 이런 경우를 고려해, 가능한 가격의 범위를 반환한다.
 * @param {number} foreignAmount - 외화로 변환된 가격 (외국 화폐 기준)
 * @param {number} exchangeRate - 환율, 대상 단위 화폐가 원화로 얼마인지. (ex. 달러 => 1200원/달러)
 */
export function exchangeIntoKoreanCurrency(foreignAmount: number, exchangeRate: number): { minValue: number; maxValue: number } {
  const tick = exchangeRate > 1 ? 0.01 : 1;
  const epsilon = 1e-10;
  const maxValueBeforeFloor = foreignAmount + tick - epsilon;
  const maxValue = Decimal.mul(maxValueBeforeFloor, exchangeRate).toDecimalPlaces(0, Decimal.ROUND_FLOOR).toNumber();
  const minValue = Decimal.mul(foreignAmount, exchangeRate).toDecimalPlaces(0, Decimal.ROUND_CEIL).toNumber();

  if (minValue > maxValue) {
    return { minValue: maxValue, maxValue: minValue };
  } // 환율이 100보다 작으며, 대응되는 원화가 없는 경우. ex) rate = 7, foreignAmount = 1.05

  return { minValue, maxValue };
}

/**
 * 외화 단위로 입력된 금액을 원화로 변환합니다.
 * @param foreignAmount 가격
 * @param langCode 외화 화폐 단위
 */
// [TODO] : 환율 로직 바꾸기.
export const convertToKoreanMoney = (foreignAmount: number | string, langCode: string): number => {
  if (langCode === LANGUAGE_CODE.KO) {
    return Number(foreignAmount);
  }

  const langs = [LANGUAGE_CODE.KO, LANGUAGE_CODE.EN, LANGUAGE_CODE.CN, LANGUAGE_CODE.JP];
  // 고정환율들
  const measure = [1000, 1, 6.25, 100];

  const convertPrice = new Decimal(Number(foreignAmount) * measure[langs.indexOf(LANGUAGE_CODE.KO)]).dividedBy(measure[langs.indexOf(langCode as LanguageCode)]);
  return convertPrice.mul(100).floor().dividedBy(100).toNumber();
};

export function replaceNonNumeric(str: string): string {
  return str.replace(/[^0-9.]/gi, '');
}

// [TODO] : components/payment/Action.ts 에도 가격 계산 로직이 들어있음. 중복되는 로직은 lib로 변환
